<template>
    <vue-final-modal v-slot="{ close }" v-bind="$attrs" classes="modal-container"
        content-class="modal-content modal-export-logs">
        <!-- Begin::Header -->
        <div class="modal__header">
            <span class="modal__title">
                Export logs
            </span>
        </div>
        <!-- End::Header -->
        <!-- Begin::Body -->
        <div class="modal__content">
            <!-- Begin::Details section -->
            <h5 class="fw-bolder mb-6">Export logs (CSV file)</h5>
            <form @submit.prevent="exportLogs" ref="form">
                <div class="d-flex flex-row row mb-4">
                    <!-- Begin::Left col -->
                    <div class="d-flex flex-column col-6 mb-5">
                        <label for="from">From</label>
                        <input type="date" class="form-control" id="from" v-model="exportLogsForm.from" />
                    </div>
                    <div class="d-flex flex-column col-6 mb-5">
                        <label for="to">To</label>
                        <input type="date" class="form-control" id="to" v-model="exportLogsForm.to" />
                    </div>
                    <!-- End::Right col -->
                </div>
            </form>
        </div>
        <!-- End::Header -->
        <!-- Begin::Footer -->
        <div class="modal__action">
            <a v-if="!exportLogsLoading" class="btn btn-lg btn-primary me-2" @click.prevent="onClickConfirm">
                Download (CSV)
            </a>
            <a v-else class="btn btn-lg btn-primary me-2 disabled" disabled>
                Exporting...
            </a>
            <a class="btn btn-lg btn-secondary" @click="$emit('cancel')">Cancel</a>
        </div>
        <!-- End::Header -->
        <button class="modal__close btn btn-icon btn-link" @click="close">
            <i class="bi bi-x-lg"></i>
        </button>
    </vue-final-modal>
</template>
  
<script>
import _ from "lodash"
import ApiService from "@/core/services/ApiService";

export default {
    emits: ["confirm", "cancel"],
    components: {
        // vSelect,
    },
    data() {
        return {
            exportLogsLoading: false,
            exportLogsForm: {
                from: null,
                to: null,
            },
        };
    },
    methods: {
        onClickConfirm() {
            this.$refs.form.requestSubmit();
        },
        exportLogs() {
            this.exportLogsLoading = true;
              ApiService
                  .query("/contact-question-logs/batch", {
                      params: {
                          from: this.exportLogsForm.from,
                          to: this.exportLogsForm.to,
                      },
                      responseType: 'arraybuffer',
                  })
                  .then(({ data }) => {
                      const url = window.URL.createObjectURL(new Blob([data]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', 'neoris-logs-export.csv'); //or any other extension
                      document.body.appendChild(link);
                      link.click();
                      this.$emit("confirm");
                      this.exportLogsLoading = false;
                  })
                  .catch((err) => {
                      console.log(err);
                      this.exportLogsLoading = false;
                  });
        },
    },
};
</script>
  
<style>
.modal-export-logs.modal-content {
    min-height: 320px;
    max-height: 500px;
}
</style>