<template>
  <div>
    <button @click.prevent="openExportLogsModal" class="btn btn-primary mb-4 me-4">
      <i class="bi bi-download me-1 mb-1"></i>Export logs (CSV)
    </button>

    <ExportLogs name="export-logs-modal" v-model="isExportLogsModalOpen"
      @cancel="closeExportLogsModal" @confirm="onLogsExported" />

    <!--begin::Table-->
    <vue-good-table mode="remote" styleClass="custom-table" :total-rows="totalRecords" :is-loading="isTableLoading"
      :pagination-options="{ enabled: true }" :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
      :rows="rows" :columns="columns" :fixed-header="true" @page-change="onPageChange" @sort-change="onSortChange"
      @column-filter="onColumnFilter" @per-page-change="onPerPageChange">
      <template #loading-content>Loading...</template>
      <template #emptystate>No entry found</template>
      <template #column-filter="{ column }">
        <div v-if="column.filterOptions && column.filterOptions.customFilter">
          From
          <input type="date" @input="(value) => handleDateRangeFilter(`${column.field}_gte`, value)"><br>
          To
          <input type="date" @input="(value) => handleDateRangeFilter(`${column.field}_lte`, value)">
        </div>
      </template>

      <template #table-row="props">
        <!-- Column: Patient's country -->
        <span v-if="props.column.field === 'patient_country'">
          {{
            props.row.patient && props.row.patient.address
              ? props.row.patient.address.country
              : ''
          }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template #pagination-bottom="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">Show </span>
            <select class="form-control mx-1" :value="serverParams.perPage"
              @change="(e) => props.perPageChanged({ currentPerPage: e.target.value })">
              <option v-for="(option, idx) in ['5', '10', '25', '50', '100']" :key="`select-perpage-${idx}`"
                :value="option">
                {{ option }}
              </option>
            </select>
            <span class="text-nowrap">
              entries per page (Total: {{ totalRecords }})
            </span>
          </div>
          <div>
            <ul class="pagination">
              <li class="page-item previous" :class="{ disabled: serverParams.page === 1 }">
                <a @click.prevent="props.pageChanged({ currentPage: serverParams.page - 1 })" href="#"
                  class="page-link">Previous</a>
              </li>
              <div v-for="idx in Math.ceil(props.total / serverParams.perPage)" class="d-flex flex-row align-items-center"
                :key="idx">
                <li class="page-item" :class="{ active: serverParams.page === idx }">
                  <a @click.prevent="props.pageChanged({ currentPage: idx })" href="#" class="page-link" v-if="idx <= 3 ||
                    (idx >= serverParams.page - 1 &&
                      idx <= serverParams.page + 1) ||
                    idx >= Math.ceil(props.total / serverParams.perPage) - 2
                    ">{{ idx }}</a>
                </li>
                <p class="mb-0 mt-1 mx-3" v-if="(idx === serverParams.page - 2 && idx > 3) ||
                  (idx === serverParams.page + 2 &&
                    idx < Math.ceil(props.total / serverParams.perPage) - 2)
                  ">
                  ...
                </p>
              </div>
              <li class="page-item next"
                :class="{ disabled: serverParams.page === Math.ceil(props.total / serverParams.perPage) }">
                <a @click.prevent="props.pageChanged({ currentPage: serverParams.page + 1 })" href="#"
                  class="page-link">Next</a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!--end::Table-->
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ExportLogs from "@/components/modals/contact-question-logs/ExportLogs";
import _ from "lodash";

export default {
  name: 'ContactQuestionLogsManager',
  components: {
    ExportLogs,
  },
  data() {
    return {
      isExportLogsModalOpen: false,
      isTableLoading: false,
      totalRecords: 0,
      columns: [
        {
          label: "Clinic",
          field: "clinic",
          formatFn: e => e ? e.name : 'No',
          filterOptions: {
            enabled: true,
            filter: true,
            placeholder: 'All',
            filterDropdownItems: [],
          },
        },
        {
          label: "Prospect",
          field: "patient",
          formatFn: e => e ? `${e.firstname} ${e.lastname}` : '',
        },
        {
          label: "Country",
          field: "patient_country",
        },
        {
          label: "Action",
          field: "logType",
          filterOptions: {
            enabled: true,
            filter: true,
            placeholder: 'All',
            filterDropdownItems: [
              { value: "QUESTION", text: "Question" },
              { value: "ACCEPT_PROSPECT", text: "Prospect Accepted" },
              { value: "REJECT_PROSPECT", text: "Prospect Rejected" },
              { value: "PROSPECT_FORWARDED", text: "Prospect Forwarded" },
            ],
          },
        },
        {
          label: "Question",
          field: "question",
          formatFn: e => e && e.title ? e.title : 'N/C',
          filterOptions: {
            enabled: true,
            filter: true,
            placeholder: 'All',
            filterDropdownItems: [],
          },
        },
        {
          label: "Date",
          field: "createdAt",
          formatFn: e => new Date(e).toLocaleDateString(),
          sortable: true,
          filterOptions: { enabled: true, customFilter: true },
        },
      ],
      rows: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "createdAt",
          type: "desc",
        },
        page: 1,
        perPage: 25,
      },
      dateFilters: {
        createdAt_gte: null,
        createdAt_lte: null,
      },
    };
  },
  mounted() {
    this.fetchItemsFromApi();
    setCurrentPageBreadcrumbs("Prospects Audit Trail (Logs)", []);

    ApiService.query('clinics', { params: { limit: 100 } }).then(({ data }) => {
      this.columns.find(e => e.field == 'clinic').filterOptions.filterDropdownItems = data.results.map(e => ({
        value: e.id,
        text: e.name,
      }));
    });

    ApiService.query('contact-questions', { params: { archived: false, limit: 100 } }).then(({ data }) => {
      this.columns.find(e => e.field == 'question').filterOptions.filterDropdownItems = data.results.map(e => ({
        value: e.id,
        text: `${e.title} (${e.hotProspect ? 'Hot' : 'Cold'})`,
      }));
    });
  },
  methods: {
    updateParams(newProps) {
      // Force date filters
      if (newProps.columnFilters) {
        newProps.columnFilters = { ...newProps.columnFilters, ...this.dateFilters };
      }
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      console.log('onPageChange', params);
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    handleDateRangeFilter: _.debounce(function (column, event) {
      let value = event.target.value;
      if (value) {
        value += column.endsWith("_gte") ? "T00:00:00" : "T23:59:59";
      }

      // Persist date filters
      this.dateFilters[column] = value;

      this.onColumnFilter({
        columnFilters: {
          ...this.serverParams.columnFilters,
          [column]: value
        }
      });
    }, 500),
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;

      const tableSearch = {};
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;
        if (['clinic', 'question', 'simulationRequest', 'logType', 'createdAt_gte', 'createdAt_lte'].includes(e)) {
          tableSearch[e] = this.serverParams.columnFilters[e];
        } else {
          tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
        }
      });

      const getApiParams = {
        ...tableSearch,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
        populate: 'clinic patient question'
      };

      ApiService
        .query('/contact-question-logs', { params: getApiParams })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;
        })
        .catch((err) => {
          this.isTableLoading = false;
        });
    },
    openExportLogsModal() {
      this.isExportLogsModalOpen = true;
    },
    closeExportLogsModal() {
      this.isExportLogsModalOpen = false;
    },
    onLogsExported() {
      this.closeExportLogsModal();
    }
  },
};
</script>

<style lang="scss" scoped>
.operations-table {
  background-color: #fff;
}
</style>
<style lang="scss">
.custom-table th.filter-th {
  color: #000;
  font-weight: normal;
}
</style>